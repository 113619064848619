import { useContext } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import * as Icons from "@phosphor-icons/react";
import {
    URI_BASE,
    URI_BRAND_ASSETS,
    URI_CLIENTS,
    URI_COMMISSIONS,
    URI_COMMUNITY_GUIDELINES,
    URI_EVENTS,
    URI_FORA_PROFILE,
    URI_FORUM_SHORTCUT,
    URI_GET_STARTED,
    URI_HOME,
    URI_LEADS,
    URI_ONBOARDING,
    URI_PARTNERS,
    URI_PARTNERS_SUPPLIERS,
    URI_PAYMENTS,
    URI_POLICIES,
    URI_RESOURCES,
    URI_SHAREABLE_ASSETS,
    URI_TRAINING_V2
} from "constants/urls";
import { BookingPlatformViewModes } from "lib/types/supplier-database.types";
import { StoreContext } from "store";
import { useAuth } from "hooks/use-auth";
import { useGetStartedEnabled } from "hooks/useGetStartedEnabled";
import { NavItem } from "../NavTabTray";
import { isLinkActive } from "utils/navigation";
import { NavTab } from "lib/types";
import { useTrainingMenu } from "./useTrainingMenu";
import { GET_STARTED_ITEM } from "../constants";

const areParametersActive = (
    currentSearchParameters: URLSearchParams | null,
    parameters: { [key: string]: string } | undefined
): boolean =>
    !currentSearchParameters ||
    !parameters ||
    Object.entries(parameters).every(([key, value]) => currentSearchParameters?.get(key) === value);
/** Sets the active flag for a nav item if it hasn't been set yet */
const setIsActive = (
    item: NavItem,
    currentPath: string,
    currentSearchParameters: URLSearchParams | null
): void => {
    if (item.active === undefined && item.url) {
        item.active =
            areParametersActive(currentSearchParameters, item.parameters) &&
            isLinkActive(item.url, currentPath);
    }
};

/** Returns a list of tabs, each with their own sub-links, representing the pages of Advisor Portal */
export const useNavTabs = (): NavTab[] => {
    const { isFeatureEnabled } = useAuth();
    const pathname = usePathname() ?? "";
    const searchParams = useSearchParams();
    const { getStartedHidden } = useGetStartedEnabled();
    const subNavGroups = useTrainingMenu({ pathname, getStartedHidden, searchParams });
    const isTrainingV3Enabled = isFeatureEnabled("training_v3");
    const showPaymentsPage = isFeatureEnabled("payments_page", "show_on_navtab");
    const showEventsPage = isFeatureEnabled("calendar_events");
    const showLeadsPage = isFeatureEnabled("leads_program");
    const showPropertyReportsPage = isFeatureEnabled("property_reports");

    const { bookingFiltersURI } = useContext(StoreContext);
    const suppliersUrl = `${URI_PARTNERS}?view_mode=${
        BookingPlatformViewModes.LIST
    }&supplierType=hotels${pathname.includes(URI_PARTNERS) ? "&clearAll=true" : ""}`;
    const propertyReportsUrl = `${URI_PARTNERS_SUPPLIERS}/property-reports`;
    const bookingsUrl = bookingFiltersURI || URI_COMMISSIONS;

    const propertyReportsActive = isLinkActive(propertyReportsUrl, pathname);

    const navTabs: NavTab[] = [
        {
            label: "Home",
            Icon: Icons.House,
            url: URI_BASE,
            subNavGroups: [
                {
                    label: "Welcome",
                    items: [
                        {
                            ...GET_STARTED_ITEM,
                            hidden: getStartedHidden,
                            active:
                                !getStartedHidden &&
                                (pathname === URI_BASE || pathname.includes(URI_GET_STARTED))
                        },
                        {
                            label: "Dashboard",
                            Icon: Icons.SquaresFour,
                            url: URI_HOME,
                            active:
                                (getStartedHidden && pathname === URI_BASE) ||
                                pathname === URI_HOME ||
                                pathname.includes(URI_ONBOARDING)
                        }
                    ]
                },
                {
                    label: "Community",
                    items: [
                        {
                            label: "Events",
                            Icon: Icons.CalendarBlank,
                            url: URI_EVENTS,
                            hidden: !showEventsPage
                        },
                        { label: "Forum", Icon: Icons.ChatsTeardrop, url: URI_FORUM_SHORTCUT },
                        {
                            label: "Policies",
                            Icon: Icons.ClipboardText,
                            url: URI_COMMUNITY_GUIDELINES,
                            active: isLinkActive(URI_POLICIES, pathname)
                        }
                    ]
                }
            ]
        },
        {
            label: "Book",
            Icon: Icons.Bed,
            url: suppliersUrl,
            subNavGroups: [
                {
                    label: "Book travel",
                    items: [
                        {
                            label: "Partners",
                            Icon: Icons.Bed,
                            url: suppliersUrl,
                            active:
                                !propertyReportsActive &&
                                isLinkActive(URI_PARTNERS_SUPPLIERS, pathname)
                        }
                    ]
                },
                {
                    label: "Browse",
                    items: [
                        {
                            label: "Property reports",
                            Icon: Icons.NewspaperClipping,
                            url: propertyReportsUrl,
                            hidden: !showPropertyReportsPage,
                            active: propertyReportsActive
                        }
                    ]
                }
            ]
        },
        {
            label: "Manage",
            Icon: Icons.PencilLine,
            url: bookingsUrl,
            subNavGroups: [
                {
                    label: "My business",
                    items: [
                        { label: "Bookings", Icon: Icons.SuitcaseRolling, url: bookingsUrl },
                        { label: "Clients", Icon: Icons.Users, url: URI_CLIENTS },
                        {
                            label: "Payments",
                            Icon: Icons.PiggyBank,
                            url: URI_PAYMENTS,
                            hidden: !showPaymentsPage
                        }
                    ]
                },
                {
                    label: "Opportunities",
                    items: [
                        {
                            label: "Leads",
                            Icon: Icons.PhoneIncoming,
                            url: URI_LEADS,
                            hidden: !showLeadsPage
                        }
                    ]
                },
                {
                    label: "Client development",
                    items: [
                        { label: "Fora profile", Icon: Icons.UserRectangle, url: URI_FORA_PROFILE },
                        {
                            label: "Shareable assets",
                            Icon: Icons.PenNib,
                            url: URI_SHAREABLE_ASSETS
                        },
                        { label: "Brand assets", Icon: Icons.ImageSquare, url: URI_BRAND_ASSETS },
                        { label: "Templates & forms", Icon: Icons.Files, url: URI_RESOURCES }
                    ]
                }
            ]
        },
        {
            label: "Learn",
            Icon: Icons.Books,
            url: isTrainingV3Enabled ? `${URI_TRAINING_V2}?page=certified` : URI_TRAINING_V2,
            subNavGroups
        }
    ];
    // Set the active flag for all sub-nav items that don't have special cases for it
    navTabs.forEach(tab =>
        tab.subNavGroups?.forEach(({ items }) =>
            items.forEach(item => {
                setIsActive(item, pathname, searchParams);
                // If the sub-nav item is active, set the entire tab to active
                if (item.active) tab.active = true;
            })
        )
    );
    return navTabs;
};
