import { useAuth } from "./use-auth";

/** Returns flags for if Get started is enabled and if it is hidden (completed/not enabled) */
export const useGetStartedEnabled = (): {
    getStartedEnabled: boolean;
    getStartedHidden: boolean;
} => {
    const { isFeatureEnabled, userProfile } = useAuth();
    const getStartedEnabled = isFeatureEnabled("getting_started");
    const getStartedHidden = !getStartedEnabled || !!userProfile?.hide_onboarding;

    return { getStartedEnabled, getStartedHidden };
};
