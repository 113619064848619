import { useCallback, useRef, useState } from "react";
import Link, { LinkProps } from "next/link";
import { Icon as PhosphorIconT } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";
import { debounce } from "lodash";
import { TooltipWrapper } from "../TooltipWrapper";
import { IATA } from "constants/index";
import { starburst } from "utils/fire-the-confetti";

export const NAV_FOCUS_OUTLINE = "focus-visible:!outline focus-visible:!outline-2";

export const subNavLinkStyles = (active?: boolean, className?: string): string =>
    twMerge(
        "flex gap-x-2 items-center rounded-xl px-4 py-2 text-sm font-medium hover:bg-nav-hover",
        active ? "text-brand bg-nav-button" : "text-nav-secondary hover:bg-brand",
        NAV_FOCUS_OUTLINE,
        className
    );

export interface StyledLinkProps
    extends LinkProps,
        Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
    Icon?: PhosphorIconT;
    active?: boolean;
    tooltip?: string;
}

/** A page link on the left-side sub-nav bar */
export const SubNavLink = ({
    Icon,
    active,
    prefetch = false,
    className,
    children,
    ...props
}: StyledLinkProps): JSX.Element => (
    <Link
        tabIndex={0}
        className={subNavLinkStyles(active, className)}
        prefetch={prefetch}
        {...props}
    >
        {Icon && <Icon size={18} weight={active ? "duotone" : "regular"} />}
        <span>{children}</span>
    </Link>
);

/** An icon button link on the right side of the top nav header */
export const HeaderIconLink = ({
    Icon,
    tooltip,
    prefetch = false,
    className,
    ...props
}: StyledLinkProps): JSX.Element => (
    <TooltipWrapper content={tooltip} place="bottom">
        <Link
            tabIndex={0}
            className={twMerge(
                "grid place-content-center size-12 text-secondary rounded-lg hover:bg-[#1414140f]",
                NAV_FOCUS_OUTLINE,
                className
            )}
            prefetch={prefetch}
            {...props}
        >
            {Icon && <Icon size={24} />}
        </Link>
    </TooltipWrapper>
);

const mouseBurst = (e: { clientX: number; clientY: number }) =>
    starburst({
        origin: {
            x: e.clientX / window.innerWidth,
            y: e.clientY / window.innerHeight
        }
    });

/** A button to copy Fora's IATA number */
export const CopyIATAButton = ({ mobile }: { mobile?: boolean }): JSX.Element => {
    const [showCopied, setShowCopied] = useState(false);
    const delayResetTooltip = useCallback(
        debounce(() => setShowCopied(false), 3000),
        []
    );
    const btnRef = useRef<HTMLButtonElement>(null);

    let toolTipText: string | undefined = undefined;
    if (showCopied) toolTipText = "Copied";
    else if (!mobile) toolTipText = "Copy IATA#";

    return (
        <TooltipWrapper content={toolTipText} place="bottom">
            <button
                ref={btnRef}
                className={
                    !mobile
                        ? `px-3 py-1.5 rounded-lg text-medium font-normal text-secondary hover:text-primary hover:bg-secondary-hover ${NAV_FOCUS_OUTLINE}`
                        : undefined
                }
                onClick={() => {
                    navigator.clipboard.writeText(IATA);

                    if (!showCopied) setShowCopied(true);
                    delayResetTooltip();
                }}
                onMouseDown={e => {
                    mouseBurst(e);
                    window.addEventListener("mousemove", mouseBurst);
                    const removeListeners = () => {
                        window.removeEventListener("mousemove", mouseBurst);
                        window.removeEventListener("mouseup", removeListeners);
                    };
                    window.addEventListener("mouseup", removeListeners);
                }}
            >
                {mobile ? "Copy Fora IATA#" : "IATA#"}
            </button>
        </TooltipWrapper>
    );
};
