export interface SupplierTab {
    slug: SupplierTypeSlugs;
    name: string;
    icon: string;
    href: string;
}

export const SUPPLIER_TYPE_ACTIVE_BASEPATHS = {
    ["lists"]: true,
    programs: true,
    ["hotels"]: true,
    ["all_inclusive"]: true,
    ["packages"]: true,
    cruises: true,
    dmcs: true,
    flights: true,
    ["home-and-villas"]: true,
    ["tours-and-activities"]: true,
    transportation: true,
    insurance: true
};

export type SupplierTypeSlugs =
    | "hotel"
    | "all_inclusive" /*this comes from the API as all include but we map to packages url*/
    | "lists"
    | "flight"
    | "cruise"
    | "DMC"
    | "home_villa"
    | "tour"
    | "ground_transportation"
    | "programs"
    | "insurance";

export type SupplierQueryParams = "all_inclusive" | "preferred" | null;

type SupplierTypeToSlugMap = {
    [s in keyof typeof SUPPLIER_TYPE_ACTIVE_BASEPATHS]: SupplierTypeSlugs;
};
export const SUPPLIER_TABS: {
    [s in SupplierTypeSlugs]: SupplierTab;
} = {
    hotel: {
        slug: "hotel",
        name: "Hotels",
        icon: "Bed",
        href: "/hotels"
    },
    all_inclusive: {
        slug: "all_inclusive",
        name: "Packages",
        icon: "SunHorizon",
        href: "/all-inclusives"
    },
    flight: {
        slug: "flight",
        name: "Flights",
        icon: "AirplaneTakeoff",
        href: "/flights"
    },
    cruise: {
        slug: "cruise",
        name: "Cruises",
        icon: "Boat",
        href: "/cruises"
    },
    DMC: {
        slug: "DMC",
        name: "DMCs",
        icon: "NavigationArrow",
        href: "/dmcs"
    },
    home_villa: {
        slug: "home_villa",
        name: "Homes & Villas",
        icon: "HouseLine",
        href: "/home-and-villas"
    },
    tour: {
        slug: "tour",
        name: "Tours & Activities",
        icon: "Globe",
        href: "/tours-and-activities"
    },
    ground_transportation: {
        slug: "ground_transportation",
        name: "Transportation",
        icon: "Car",
        href: "/transportation"
    },
    programs: {
        slug: "programs",
        name: "Preferred partners",
        icon: "Handshake",
        href: "/programs"
    },
    insurance: {
        slug: "insurance",
        name: "Insurance",
        icon: "ShieldCheck",
        href: "/insurance"
    },
    lists: {
        slug: "lists",
        name: "My lists",
        icon: "Heart",
        href: "/my-lists"
    }
};

export const SupplierTypeToSlugMap: SupplierTypeToSlugMap = {
    hotels: "hotel",
    programs: "programs",
    ["lists"]: "lists",
    all_inclusive: "all_inclusive",
    cruises: "cruise",
    dmcs: "DMC",
    flights: "flight",
    "home-and-villas": "home_villa",
    ["tours-and-activities"]: "tour",
    transportation: "ground_transportation",
    insurance: "insurance",
    packages: "all_inclusive"
};

export type SupplierFilterLabelSlugs = "preferred" | "no_rates";

export type InfoBlockTypeOptions = "text" | "text-links";

export type InfoBlock = {
    blockType: InfoBlockTypeOptions;
    title: string;
    titleIcon: string;
    content: string | InfoBlockTextLink[];
};
export interface InfoBlockTextLink {
    label: string;
    url: string;
}

export type GuideSupplierDB = {
    title: string;
    slug: string;
    data: InfoBlock[];
};

export enum CheckoutErrorSlugs {
    travelport_unknown_exception = "travelport_unknown_exception",
    travelport_price_mismatch = "travelport_price_mismatch",
    invalid = "invalid"
}

export type CheckoutErrorResponse = {
    detail: string;
    code: string;
    program_id?: string;
    client_loyalty_program_id?: string;
    slug?: string;
};

export enum BookingPlatformViewModes {
    LIST = "list",
    MAP = "map"
}

export type MapLongLat = [long: number, lat: number];
