import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { Icon as PhosphorIconT } from "@phosphor-icons/react";
import { NAV_FOCUS_OUTLINE } from "./nav-links";

const NAV_BG = "bg-brand";
const TAB_BG = "bg-surface-1";
const HOVER_BG = "hover:bg-brand-strong/10";

const BASE_TAB_STYLES =
    "relative flex gap-x-2 items-center p-4 size-fit rounded-xl text-secondary select-none";

// CURVE RADIUS: 12px
const WING_BAR_MOBILE = "flex-col -inset-y-3 -right-2";
const WING_BAR_TABLET = "-inset-x-3 -bottom-1";
const WING_TIP_STYLE = "size-3";

export const TAB_SHADOW = "desktop:shadow-[0_-4px_8px_rgba(0,0,0,0.05)]";

/** An item defining a navigation tab or link */
export type NavItem = {
    label?: string;
    Icon?: PhosphorIconT;
    url?: string;
    hidden?: boolean;
    active?: boolean;
    newTab?: boolean;
    parameters?: Record<string, string>;
};

interface NavTabProps extends NavItem {
    onClick?: () => void;
    mobile?: boolean;
}

/** A curvy tab associated with a group of pages  */
const NavTab = ({
    label,
    Icon,
    url,
    active,
    newTab,
    onClick,
    mobile
}: NavTabProps): JSX.Element => {
    const tabProps = {
        className: active
            ? twMerge(
                  // Active tab styles
                  BASE_TAB_STYLES,
                  "text-primary",
                  TAB_SHADOW,
                  TAB_BG,
                  mobile ? "rounded-r-none" : "rounded-b-none"
              )
            : twMerge(
                  // Inactive tab styles
                  BASE_TAB_STYLES,
                  "z-1 cursor-pointer hover:text-primary",
                  NAV_FOCUS_OUTLINE,
                  HOVER_BG
              ),
        onClick,
        children: (
            <>
                {/* Only icons on mobile */}
                {Icon &&
                    (mobile ? (
                        <Icon size={24} weight={active ? "fill" : "regular"} />
                    ) : (
                        <>
                            <Icon size={20} className="mb-0.5" />
                            <span className="text-mediumFS16 font-medium">{label}</span>
                        </>
                    ))}
                {/* Apply curves to the bottom of active tabs */}
                {active && (
                    <div
                        className={twMerge(
                            "absolute flex justify-between",
                            mobile ? WING_BAR_MOBILE : WING_BAR_TABLET,
                            TAB_BG
                        )}
                    >
                        <div className={twMerge("rounded-br-full", NAV_BG, WING_TIP_STYLE)} />
                        <div
                            className={twMerge(
                                mobile ? "rounded-tr-full" : "rounded-bl-full",
                                NAV_BG,
                                WING_TIP_STYLE
                            )}
                        />
                    </div>
                )}
            </>
        )
    };
    // Render as buttons on mobile, links on desktop
    return mobile || !url ? (
        <button {...tabProps} />
    ) : (
        <Link {...tabProps} href={url} target={newTab ? "_blank" : undefined}></Link>
    );
};

export type NavTabTrayProps<T extends NavItem> = {
    /** The tabs to display */
    tabs: T[];
    /** If defined, sets this tab as active. If not, uses each tab's active flag. */
    activeTab?: T;
    /** A callback for when tabs are clicked */
    onClickTab?: (tab: T) => void;
    /** If true, orients vertically with only icons on the tabs, with tabs opening to the right */
    mobile?: boolean;
};

/** A tray of tabs for portal navigation. Oriented horizontally by default or vertically with mobile set to true */
export const NavTabTray = <T extends NavItem>({
    tabs,
    activeTab,
    onClickTab,
    mobile
}: NavTabTrayProps<T>): JSX.Element => (
    <div
        className={
            mobile
                ? "flex desktop:hidden flex-col gap-y-2 pl-3 pr-2 py-8 isolate"
                : "hidden desktop:flex flex-row gap-x-1 pb-1 pt-2 isolate"
        }
    >
        {tabs.map(
            tab =>
                !tab.hidden && (
                    <NavTab
                        key={tab.label}
                        {...tab}
                        active={activeTab ? activeTab.label === tab.label : tab.active}
                        onClick={() => onClickTab?.(tab)}
                        mobile={mobile}
                    />
                )
        )}
    </div>
);
