import React from "react";
import { useRouter } from "next/router";
import { deleteCookie, getCookies } from "cookies-next";
import { signIn, signOut, SignOutParams, useSession } from "next-auth/react";
import { URI_LOGIN, URL_ADMIN_USERS_LIST } from "constants/urls";
import { AuthProviders } from "constants/index";

/** A red bar at the top of the page indicating that you are impersonating another user */
export const ImpersonatedUserBar = (): JSX.Element | null => {
    const router = useRouter();
    const { data: session } = useSession();
    if (!session?.isImpersonating) return null;

    const userProfile = session?.userProfile;

    const handleDetachUser = async () => {
        const toDetach = session?.accessToken !== session?.originalToken;
        const signOutOptions: SignOutParams<any> = {};
        const backLink = "";
        if (!toDetach) {
            signOutOptions.callbackUrl = `${URI_LOGIN}${backLink}`;
        } else {
            signOutOptions.redirect = false;
        }
        await signOut(signOutOptions);
        if (toDetach) {
            try {
                await signIn(AuthProviders.LOGIN_AS_ADVISOR, {
                    originalToken: session?.originalToken
                }).then(() => {
                    const cookies = Object.keys(getCookies());
                    cookies.forEach(key => deleteCookie(key));
                });
            } catch (error) {
                console.log("Error handler was fired on the getting user token", error);
            } finally {
                router.push(URL_ADMIN_USERS_LIST, undefined, {
                    shallow: true
                });
            }
        }
    };

    return (
        <div
            className="flex-shrink-0 flex px-4 lg:px-6 py-4 lg:pb-3 lg:pt-[11px] bg-link text-white pointer-events-none lg:pointer-events-auto impersonate-wrapper"
            data-testid="impersonate-wrapper"
        >
            <div
                className="flex items-center text-medium font-medium mr-2 lg:mx-auto"
                data-testid="impersonate-content"
            >
                You are impersonating {userProfile?.firstName} {userProfile?.lastName} (
                {userProfile?.email as string})
            </div>
            <button
                className="inline-flex ml-auto lg:ml-0 items-center justify-center px-4 py-3 font-medium bg-white text-main text-small rounded-3xl border border-stroke focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-600"
                onClick={handleDetachUser}
                data-testid="impersonate-detach"
            >
                Detach
            </button>
        </div>
    );
};
